require([
    'jquery',
    'domReady!'
], function ($){
    //'use strict';
    // Smooth scroll anchors
    $(document).on('click', 'a[href^="#"]', function (event) {
        event.preventDefault();
        if ($.attr(this, 'href') === '#') { return; }

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 500);
    });
});

